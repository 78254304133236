<template>
	<div class="container">
		<div class="row header">
			<div class="col">
				<h3>Certificates and Reports</h3>
			</div>
			<div class="col text-right">
				<a class="btn btn-brand shadow-none text-brand font-weight-bold text-decoration-underline" @click="goTo('NewCourse')">New Report</a>
			</div>

			<div class="brand-tabs">
				<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="brand-tabs-container"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<div class="row my-4">
				<div class="col">
					SORT
				</div>
				<div class="col">
					<input type="text"
						v-model="searchText"
						class="form-control w-100 search-brand shadow-none border-0 py-1"
						placeholder="SEARCH"
						:loading="isLoading">
					<form-message v-if="message">{{ message }}</form-message>
				</div>
			</div>

		<v-tabs-items
			v-model="currentItem"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component
						:is="tab.component"
						:certificates="certificates"
						:deletedCertificates="deletedCertificates"
						:savedCertificates="savedCertificates"
						:displayedCertificates="displayedCertificates"
					/>
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
			</div>

		</div>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-header>
					Certificates and Reports
				</admin-default-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<admin-default-description
					>Review and download certificates and reports of your
					learners. These certificates can serve as a proof of
					completion for your learners, and they provide great
					insights into your learner’s progress and
					performance.</admin-default-description
				>
			</v-col>
			<v-col class="d-flex flex-row align-start justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					@click="goTo('NewCourse')"
					>New Report</v-btn
				>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<label class="admin-default-label primary-text"
					>Search for Users, Groups or Reports</label
				>
				<v-text-field
					v-model="selectedQuizName"
					class="search-course"
					placeholder="Start typing to Search"
					filled
					:loading="isLoading"
				></v-text-field>
			</v-col>
		</v-row>

		<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<v-tabs-items
			v-model="currentItem"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component
						:is="tab.component"
						:certificates="certificates"
						:deletedCertificates="deletedCertificates"
						:savedCertificates="savedCertificates"
						:displayedCertificates="displayedCertificates"
					/>
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
	</v-container> -->
</template>

<script>
import CertificatesList from "./CertificatesList.vue";
import CertificatesSaved from "./CertificatesSaved.vue";
import CertificatesDeleted from "./CertificatesDeleted.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";
import { get } from "@/util/requests/get";
import InputTextField from "@/components/InputTextField.vue";
import { mapGetters } from "vuex";

export default {
	name: "Certificates",
	components: {
		CertificatesList,
		CertificatesSaved,
		CertificatesDeleted,
		AdminDefaultHeader,
		AdminDefaultDescription,
		InputTextField
	},
	computed: {
		...mapGetters(["isLoading"]),
		displayedCertificates() {
			const { certificates } = this.tabs.find(
				el => el.component === this.currentItem
			);
			return this[certificates]?.filter(el =>
				String(el.course?.title).includes(this.selectedQuizName)
			);
		}
	},
	data() {
		return {
			templateName: "",
			currentItem: "CertificatesList",
			tabs: [
				{
					title: "Certificates",
					value: "certificateslist",
					component: "CertificatesList",
					certificates: "certificates"
				}
				// {
				// 	title: "Saved Reports",
				// 	value: "certificatessaved",
				// 	component: "CertificatesSaved",
				// 	certificates: "savedCertificates"
				// },
				// {
				// 	title: "Deleted Reports",
				// 	value: "certificatesdeleted",
				// 	component: "CertificatesDeleted",
				// 	certificates: "deletedCertificates"
				// }
			],
			certificates: null,
			deletedCertificates: null,
			savedCertificates: null,
			selectedQuizName: ""
		};
	},
	methods: {
		goTo(name, query) {
			this.$router.push({ name: name, query: query });
		},
		async getCertificates(query = "") {
			const {
				data: { data }
			} = await get(`/reports?${query}`);
			return data;
		}
	},
	async created() {
		await this.$store.dispatch("setIsLoading", true);
		this.certificates = await this.getCertificates("include=user,course");
		this.savedCertificates = await this.getCertificates(
			"include=user,course&filters=is_complete&filter_values=1"
		);
		this.deletedCertificates = await this.getCertificates(
			"include=user,course&only_trashed=true"
		);
		await this.$store.dispatch("setIsLoading", false);
	}
};
</script>

<style></style>
